<template>
  <div class="page">
    <div class="top_card">
      <el-form label-width="100px">
        <div class="form_flex">
          <el-form-item label="消息类别：">
            <el-radio-group v-model="queryInfo.type" @change="changeSearchangeSearch">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="业务内容">业务内容</el-radio-button>
              <el-radio-button label="宣传物料">宣传物料</el-radio-button>
              <el-radio-button label="其他">其他</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <div class="margin_right">
            <el-form-item label="消息类型：">
              <el-checkbox-group v-model="contentTypes" @change="unitChange">
                <el-checkbox-button label="">全部</el-checkbox-button>
                <el-checkbox-button label="文本">文本</el-checkbox-button>
                <el-checkbox-button label="图片">图片</el-checkbox-button>
                <el-checkbox-button label="视频">视频</el-checkbox-button>
                <el-checkbox-button label="附件">附件</el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="发布日期：">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="dateTime"
            @change="checkParamsData"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="center_row">
      <div class="row_left">
        <div class="item">
          <span class="txt">当前条件下共查出</span>
          <span class="txt color1">{{ total }}</span>
          <span class="txt">条内容</span>
        </div>
        <div class="sort">
          <span @click="handleSort(sortVal)">按总发送群</span>
          <div class="sort_icon">
            <div
              class="triangle_top_box"
              :class="isActive === 'up' ? 'top_active' : ''"
              @click="handleSort('up')"
            ></div>
            <div
              class="triangle_bottom_box"
              :class="isActive === 'down' ? 'bottom_active' : ''"
              @click="handleSort('down')"
            ></div>
          </div>
        </div>
        <div class="sort">
          <span @click="handleSorts(sortVals)">按群查看</span>
          <div class="sort_icon">
            <div
              class="triangle_top_box"
              :class="isActives === 'up' ? 'top_active' : ''"
              @click="handleSorts('up')"
            ></div>
            <div
              class="triangle_bottom_box"
              :class="isActives === 'down' ? 'bottom_active' : ''"
              @click="handleSorts('down')"
            ></div>
          </div>
        </div>
        <div class="sort">
          <span @click="handleSortss(sortValss)">按用户查看</span>
          <div class="sort_icon">
            <div
              class="triangle_top_box"
              :class="isActivess === 'up' ? 'top_active' : ''"
              @click="handleSortss('up')"
            ></div>
            <div
              class="triangle_bottom_box"
              :class="isActivess === 'down' ? 'bottom_active' : ''"
              @click="handleSortss('down')"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="card_list" v-loading="loading"  element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading">
      <div class="card_item" v-for="(item, index) in cardList" :key="index">
        <div class="po_left " v-if="item.type == '宣传物料'">
             <img class="img_left" src="../../../assets/image/top2.png" alt="">
            </div>
            <div class="po_left " v-if="item.type == '业务内容'">
              <img class="img_left" src="../../../assets/image/top1.png" alt="">
            </div>
          
            <div class="po_left " v-if="item.type == '其他'">
              <img  class="img_left" src="../../../assets/image/top3.png" alt="">
            </div>
        <div class="card_left">
          <div class="img_box" @click="clcikRedact(item)">
            
            <div class="videoUrl" v-if="item.videoUrl">
              <img src="../../../assets/image/videoicon.png" alt="" />
            </div>
            <div v-if="item.type == '业务内容'">
              <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
              <video v-else-if="item.videoUrl" ref="videoPlayer" controls>
                <source :src="item.videoUrl" type="video/mp4" />
              </video>
              <img v-else src="../../../assets/image/qf1.png" alt="" />
            </div>
            <div v-if="item.type == '节日祝福'">
              <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
              <video v-else-if="item.videoUrl" ref="videoPlayer" controls>
                <source :src="item.videoUrl" type="video/mp4" />
              </video>
              <img v-else src="../../../assets/image/qf2.png" alt="" />
            </div>
            <div v-if="item.type == '其他'">
              <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
              <video v-else-if="item.videoUrl" ref="videoPlayer" controls>
                <source :src="item.videoUrl" type="video/mp4" />
              </video>
              <img v-else src="../../../assets/image/qf3.png" alt="" />
            </div>
            <div v-if="item.type == '宣传物料'">
              <img v-if="item.photoUrl" :src="item.photoUrl" alt="" />
              <video v-else-if="item.videoUrl" ref="videoPlayer" controls>
                <source :src="item.videoUrl" type="video/mp4" />
              </video>
              <img v-else src="../../../assets/image/qf1.png" alt="" />
            </div>
          </div>
          <div class="info_box">
            <div class="row_flex">
              <div class="enterprise_name" @click="toEnterpriseDetails(item)">
                {{ item.title }}
              </div>
            </div>

            <div class="row_margin">
              <span class="clueType_margin">消息类型：</span>

              <img
                v-if="item.ischeck"
                class="icon_img"
                src="../../../assets/image/txt1.png"
                alt=""
              />
              <span v-if="item.ischeck">文本</span>
              <img
                v-if="item.ischend"
                class="icon_img"
                src="../../../assets/image/video1.png"
                alt=""
              />
              <span v-if="item.ischend">视频</span>
            </div>
            <div class="row_flex">
              <!-- <span class="enterprise_txt">{{ item.briefIntroduction }}</span> -->
              <span class="enterprise_txt">发送时间：{{ item.createTime }}</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="card_right_left" v-if="!item.isDraft">
            <div class="look_shu" @click="dialogClick(0, '总发送群', item.id)">
              <div class="color_num">
                <span>{{ item.groupCnt }}</span>
              </div>
              <div class="name_size">
                <span>总发送群</span>
              </div>
            </div>

            <div class="look_shu" @click="dialogClick(1, '已发送群', item.id)">
              <div class="color_num">
                <span>{{ item.groupCntSend }}</span>
              </div>
              <div class="name_size">
                <span>已发送群</span>
              </div>
            </div>

            <div class="look_shu" @click="dialogClick(2, '未发送群', item.id)">
              <div class="color_num1">
                <span>{{ item.groupCntNoSend || 0 }}</span>
              </div>
              <div class="name_size">
                <span>未发送群</span>
              </div>
            </div>
          </div>
          <div class="border_left"></div>
          <div class="card_right_right" v-if="item.type != '节日祝福' && !item.isDraft">
            <div class="look_shu" @click="dialogClick(3, '查看群聊数', item.id)">
              <div class="color_num">
                <span>{{ item.groupCntView ||0}}</span>
              </div>
              <div class="name_size">
                <span>群查看</span>
              </div>
            </div>

            <div class="look_shu" @click="checkClick(item)">
              <div class="color_num">
                <span>{{ item.userCntView||0 }}</span>
              </div>
              <div class="name_size">
                <span>用户查看</span>
              </div>
            </div>

            <div class="look_shu" @click="dialogClick(4, '未查看群', item.id)">
              <div class="color_num1">
                <span>{{ item.groupCntNoView || 0 }}</span>
              </div>
              <div class="name_size">
                <span>未查看群</span>
              </div>
            </div>
            <div class="look_shu">
              <div class="color_num2">
                <span>{{ item.groupCntViewPercent || 0 }}%</span>
              </div>
              <div class="name_size">
                <span>群查看率</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card_right_but">
          <div class="but_top">
            <div class="down_q" @click="clcikRedact(item)">
              <span>详情</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
  
  <script>
import { getMessageInfoPage } from '@/api/operatorCenter'

const defaultQueryInfo = Object.freeze({
  contentType: '',
  endTime: '',
  isAsc: '',
  orderByColumn: '',
  orderByParam: '',
  pageNum: 1,
  pageSize: 10,
  startTime: '',
  type: ''
})
export default {
  name: 'OperatorManage',
  components: {},
  data() {
    return {
      contentTypes: [''],
      sortCount: 0, // 排序计数
      cardList: [],
      dateTime: [],
      ids: [], //
      idsList: [], //
      loading:false,
      total: 0,
      checkAll: false,
      isIndeterminate: false, //显示半选图标
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      sortVals: 'up', // 排序点击事件的传参
      isActives: '', // 排序图标样式
      sortValss: 'up', // 排序点击事件的传参
      isActivess: '', // 排序图标样式
      queryInfo: { ...defaultQueryInfo } //查询传参
    }
  },
  created() {
    this.search()
  },
  methods: {
    checkParamsData(val) {
      if (val) {
        this.queryInfo.startTime = val[0]
        this.queryInfo.endTime = val[1]
      } else {
        this.queryInfo.startTime = ''
        this.queryInfo.endTime = ''
      }

      this.search()
    },
    unitChange(val) {
      this.queryInfo.contentType = ''
      let list = []
      val.forEach((el, i) => {
        if (el) {
          list.push(el)
        }
      })
      console.log(list)

      this.queryInfo.contentType = list.join(',')
      this.search()
    },
    // 查询一栏
    async search() {
      this.loading=true
      const { data: res } = await getMessageInfoPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.cardList = res.data.list
        this.cardList.forEach((el) => {
          if (el.contentType) {
            el.contentType = el.contentType.split(',')
            el.contentType.forEach((o) => {
              if (o) {
                if (o == '文本') {
                  this.$set(el, 'ischeck', true)
                } else {
                  this.$set(el, 'ischend', true)
                }
              }
            })
          }
        })

        this.total = res.data.total
      }
      this.loading=false
    },

    //排序
    handleSort(val) {
      this.isActive = val
      if (val === 'up') {
        this.queryInfo.orderByParam = 'group_cnt asc'
        this.sortVal = 'down'
        this.search()
      } else {
        this.queryInfo.orderByParam = 'group_cnt desc'
        this.sortVal = 'up'
        this.search()
      }
    },
    //排序
    handleSorts(val) {
      this.isActives = val
      if (val === 'up') {
        this.queryInfo.orderByParam = 'group_cnt_view asc' 
        this.sortVals = 'down'
        this.search()
      } else {
        this.queryInfo.orderByParam = 'group_cnt_view desc'
        this.sortVals = 'up'
        this.search()
      }
    },
    //排序
    handleSortss(val) {
      this.isActivess = val
      if (val === 'up') {
        this.queryInfo.orderByParam = 'user_cnt_view asc'
        this.sortValss = 'down'
        this.search()
      } else {
        this.queryInfo.orderByParam = 'user_cnt_view desc'
        this.sortValss = 'up'
        this.search()
      }
    },
    //用户查看
    checkClick(item){
      this.$router.push({
        path: '/operatorcenter/userview',
          query: {
           
            id:item.id
          }
        })
    },
    //群聊
    dialogClick(index, name, id) {
      if (name == '总发送群') {
        this.$router.push({
          path: '/content/messaement',
          query: {
            type: index,
            name,
            id
          }
        })
      } else if (name == '已发送群') {
        this.$router.push({
          path: '/content/messaementyj',
          query: {
            type: index,
            name,
            id
          }
        })
      } else if (name == '未发送群') {
        this.$router.push({
          path: '/content/messaementwei',
          query: {
            type: index,
            name,
            id
          }
        })
      } else if (name == '查看群聊数') {
        this.$router.push({
          path: '/content/messaementykan',
          query: {
            type: index,
            name,
            id
          }
        })
      } else if (name == '未查看群') {
        this.$router.push({
          path: '/content/messaementchakan',
          query: {
            type: index,
            name,
            id
          }
        })
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化 配置智参中心
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },

    clcikRedact(item) {
      this.$router.push({
        path: '/operatorcenter/details',
        query: {
          id: item.id
        }
      })
    },
    // 搜索
    changeSearchangeSearch() {
      this.search()
    }
  }
}
</script>
  
  <style lang="less" scoped>
.page {
  width: 100%;
  height: 100%;
  font-family: 'PingFang SC';

  .input_box {
    width: 300px;
  }
  .search_right {
    width: 460px;
    margin-left: auto;
    margin-right: 20px;
  }
  .search_right ::v-deep .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
  .btn_list {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .row {
    display: flex;
  }
}
.new_page {
  width: 100%;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  // border-top: #fafafa 1px solid;
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-top: 20px;
  box-sizing: border-box;
  .form_flex {
    display: flex;
    align-items: center;
    .margin_right {
      margin-left: 100px;
    }
  }
}
.center_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  .row_left,
  .row_right {
    display: flex;
    align-items: center;
  }
  .row_left {
    display: flex;
    .item {
      .txt {
        font-size: 16px;
        line-height: 24px;
        color: #393939;
      }
    }
    .sort {
      display: flex;
      align-items: center;
      margin-left: 20px;
      span {
        cursor: pointer;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #595959;
        margin-right: 5px;
      }
      .sort_icon {
        cursor: pointer;
        display: flex;
        flex-flow: column;
      }
    }
  }
  .row_right {
    margin-right: 20px;
    .txt {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
video::-webkit-media-controls {
  display: none !important;
}
::v-deep .el-checkbox-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 7px 12px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #333333;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 0;
  top: -1px;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 0.0625rem solid #fff !important;
  border-radius: 0.25rem 0 0 0.25rem;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}
::v-deep .el-radio-button .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff;
  background-color: #448aff;
  border-color: #448aff;
  box-shadow: -1px 0 0 0 #448aff;
  border-radius: 4px;
}

.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: calc(100vh - 350px);

  .card_item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px 20px 15px 15px;
    box-sizing: border-box;
    position: relative;
    z-index: inherit;
    .po_left {
          position: absolute;
          width: 84px;
          height: 30px;
          text-align: center;
          line-height: 23px;
          left: 10px;
          top: 10px;
          z-index: 99 !important;
          border-radius: 2px;
          color: #fff;
          font-size: 12px;
        }
       .img_left{
        width: 84px;
        height: 30px;
        z-index: 99999 !important;
       }
    .card_left {
      display: flex;
      align-items: center;
    
      box-sizing: border-box;
      width: 30%;
      margin-right: 20px;

      .img_box {
        width: 164px;
        height: 88px;
        margin-right: 10px;
        border-radius: 4px;
        overflow: hidden;
        position: relative;
       
        .videoUrl {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); /* 垂直和水平居中 */
          img {
            width: 30px;
            height: 30px;
          }
        }
        img {
          width: 164px;
          height: 88px;
        }
        video {
          width: 164px;
          height: 88px;
        }
       
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        .row_margin {
          margin: 15px 0px;
          display: flex;
          align-items: center;
          font-size: 14px;
          .icon_img {
            width: 16px;
            height: 16px;
            margin: 0px 5px;
          }

          .clueType_right {
            margin-left: 10px;
            max-width: 340px; /* 固定宽度超出隐藏 */
            overflow: hidden; /*  超出隐藏 */
            text-overflow: ellipsis; /*  超出以 ...显示 */
            display: -webkit-box;
            -webkit-line-clamp: 1; /* // 显示1行 */
            -webkit-box-orient: vertical;
          }
        }
        .row_flex {
          display: flex;
          align-items: center;
          font-size: 14px;
        }
        .enterprise_txt {
          max-width: 340px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }
        .enterprise_name {
          cursor: pointer;
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000;
          max-width: 300px; /* 固定宽度超出隐藏 */
          overflow: hidden; /*  超出隐藏 */
          text-overflow: ellipsis; /*  超出以 ...显示 */
          display: -webkit-box;
          -webkit-line-clamp: 1; /* // 显示1行 */
          -webkit-box-orient: vertical;
        }
        .enterprise_name:hover {
          color: #448aff;
        }
      }
    }
    .card_right {
      flex: 1;
      display: flex;
      align-items: center;
      .card_right_left {
        display: flex;
        align-items: center;

        height: 88px;
        width: 340px;
        border-radius: 4px;
        .border_left {
          height: 57px;
          width: 1px;
          background: #e8e8e8;
        }
        .look_shu {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin: 0px 20px;
          cursor: pointer;
          .color_num {
            font-size: 20px;
            color: #448aff;
          }
          .color_num1 {
            font-size: 20px;
            color: #ff5555;
          }

          .name_size {
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }
      .center_zhuanhua {
        width: 32px;
        height: 30px;
        margin: 0px 15px;
      }
      .border_left {
        height: 57px;
        width: 1px;
        background: #e8e8e8;
      }
      .card_right_right {
        display: flex;
        align-items: center;

        height: 88px;
        width: 440px;
        border-radius: 4px;

        .look_shu {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin: 0px 20px;
          cursor: pointer;
          .color_num {
            font-size: 20px;
            color: #448aff;
          }
          .color_num1 {
            font-size: 20px;
            color: #ff5555;
          }
          .color_num2 {
            font-size: 20px;
            color: #ff7d18;
          }
          .name_size {
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }
    }
    .card_right_but {
      min-width: 200px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      margin-left: 20px;
      color: #999999;
      font-size: 12px;
      .but_top {
        display: flex;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        color: #448aff;
      }
    }
  }
}
.top_card ::v-deep .city-select .dic-select {
  width: 188px;
}
.el-form-item ::v-deep .el-form-item__label {
  color: #7c7f8e;
}
.el-form-item ::v-deep .el-input {
  width: 100%;
}
.el-form-item ::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.el-form-item__content ::v-deep .upload-demo {
  width: 100px;
}
.el-radio-button ::v-deep .el-radio-button__inner {
  border-radius: 4px !important;
  border: 0 !important;
}
.ft-14 {
  font-size: 14px;
  line-height: 20px;
}
.color1 {
  color: #4e93fb;
}
.color2 {
  color: #333;
}
.color3 {
  color: #fd5469;
}
.color4 {
  color: #969495;
}
</style>
  